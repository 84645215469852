import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormFeedback,
} from 'reactstrap'
import useForm from '../../hooks/useForm'
import CustomAlert from '../Alerts'
import { deleteAccountValidations } from '../../validations'
import { DELETE_ACCOUNT } from '../../data/apis'
import { postData } from '../../reducers/app'
import InvisibleRepatcha from '../InvisibleRepatcha'

const init = {
  feedback: '',
  captcha: true,
}

let deleteAccountForm

const DeletAccountForm = ({ toggle, isOpen }) => {
  const dispatch = useDispatch()
  const {
    loading,
    loaded,
    data: { msg },
    error,
  } = useSelector(({ app }) => app.deleteAccount)

  const {
    values,
    errors,
    setValues,
    handleOnChange,
    handleSubmit,
    isSubmitting,
  } = useForm(init, deleteAccountValidations, val => {
    deleteAccountForm = {
      feedback: val.feedback,
    }
    return deleteAccountForm
  })

  const submitForm = useCallback(
    token => {
      dispatch(
        postData(DELETE_ACCOUNT, {
          ...deleteAccountForm,
          token,
        })
      ).then(() =>
        setValues({
          ...init,
        })
      )
      const { grecaptcha } = window
      grecaptcha.reset()
    },
    [isSubmitting]
  )

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} unmountOnClose={false}>
        <ModalHeader toggle={toggle}>Are you sure ?</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="mb-2 font-weight-bold text-primary">
              <i className="far fa-comment-dots mr-1"></i>
              Provide us the your feedback before deleting your account so that
              we can improve our services and serve better near future.
            </div>
            <Input
              name="feedback"
              type="textarea"
              placeholder="Your valuable feedback."
              rows={5}
              onChange={handleOnChange}
              value={values.feedback}
              invalid={!!(errors && errors.feedback)}
            />
            <FormFeedback>{errors && errors.feedback}</FormFeedback>
          </ModalBody>
          <ModalFooter>
            <InvisibleRepatcha onResolved={submitForm} />
            <Button type="submit" color="danger" disabled={loading}>
              {loading ? 'Please wait...' : 'Submit & Delete'}
            </Button>
          </ModalFooter>
        </form>
        {loaded && !loading && (
          <CustomAlert type={`${!error ? 'success' : 'danger'}`}>
            <span>{error || msg}</span>
            <div style={{ display: 'none' }}>
              {/* eslint-disable-next-line no-return-assign */}
              {setTimeout(() => (window.location.href = '/'), 3000)}
            </div>
          </CustomAlert>
        )}
      </Modal>
    </div>
  )
}

DeletAccountForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default DeletAccountForm
