import React, { useReducer, useEffect, useState } from 'react'
import { Tooltip, CustomInput } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { Toast, ToastBody, ToastHeader } from 'reactstrap'

import CustomAlert from '../Alerts'

import { postData as updateSettings } from '../../reducers/app'

import { UPDATE_USERSETTINGS } from '../../data/apis'

import { isPremiumUser, userSettings } from '../../selectors/user'
import { setUpdatedSettings } from '../../reducers/user'
import DeletAccountForm from '../DeleteAccount'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const init = {
  dlhistory: {
    service: 'dlhistory',
    is_enabled: false,
    data: '',
  },
  dlhistoryTTP: false,
}

const UserSettings = () => {
  const [services, setReducerState] = useReducer(reducer, init)
  const isVIP = useSelector(isPremiumUser)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const toggleModal = () => setIsOpen(!isOpen)

  const {
    updatesettings: { loading, loaded, data, error },
  } = useSelector(state => state.app)
  const settings = useSelector(userSettings)

  useEffect(() => {
    if (settings && settings.length > 0) {
      setReducerState({
        type: 'SET_STATE',
        payload: settings.reduce(
          (a, b) => ({
            ...a,
            [b.service]: b,
          }),
          {}
        ),
      })
    }
  }, [])

  const toggle = e => {
    const {
      target: { id },
    } = e
    setReducerState({
      type: 'SET_STATE',
      payload: {
        ...services,
        [id]: !services[id],
      },
    })
  }

  const handleCheckBox = e => {
    const {
      target: { checked, name },
    } = e
    setReducerState({
      type: 'SET_STATE',
      payload: {
        ...services,
        [name]: {
          ...services[name],
          is_enabled: checked,
        },
      },
    })
    const payload = {
      service: name,
      data: services[name].data,
      is_enabled: checked,
    }
    dispatch(
      updateSettings(UPDATE_USERSETTINGS, payload, () =>
        dispatch(setUpdatedSettings(payload))
      )
    )
  }

  return (
    <div className="mt-4 col-auto col-sm-12">
      <Toast>
        <ToastHeader>
          <i className="fas fa-info-circle text-primary" id="dlhistoryTTP" />
          Download History
        </ToastHeader>
        <ToastBody>
          <CustomInput
            type="switch"
            id="dlhistory"
            name="dlhistory"
            checked={!!services.dlhistory.is_enabled}
            onChange={handleCheckBox}
            disabled={!isVIP || loading}
            label={`${
              services.dlhistory.is_enabled ? 'Disable' : 'Enable'
            } Download History`}
          />
          {!isVIP ? (
            <>
              <span className="text-danger">
                <i className="fas fa-exclamation-triangle" />
                Only premium users can enable download history option.
              </span>
            </>
          ) : (
            <div className="control-group pt-2 text-dark">
              Currently you have{' '}
              {services.dlhistory.is_enabled ? (
                <span className="text-success">Enabled</span>
              ) : (
                <span className="text-danger">Disabled</span>
              )}{' '}
              download history.
            </div>
          )}
          {loaded && !loading && (
            <CustomAlert type={`${error ? 'danger' : 'success'}`}>
              <span>{error || (data && data.msg)}</span>
            </CustomAlert>
          )}
        </ToastBody>
      </Toast>
      <Tooltip
        placement="left"
        isOpen={services.dlhistoryTTP}
        target="dlhistoryTTP"
        toggle={toggle}
      >
        <div className="d-flex flex-column text-left">
          <div>1. Only Premium users can enable download history.</div>
          <div>
            2. We store your history of downloads upto 24 hours from the time
            the link is generated.
          </div>
          <div>3. Links older than 24 hours will be automatically deleted.</div>
        </div>
      </Tooltip>
      <Toast className="text-danger">
        <ToastHeader>
          <i className="fas fa-user-times"></i>
          Delete Account
        </ToastHeader>
        <ToastBody>
          {isVIP ? (
            <span className="text-danger text-bold">
              <i className="fas fa-exclamation-triangle" />
              You're a Premium user so for deleting your account please contact
              us.
            </span>
          ) : (
            <button
              className="btn btn-danger btn-sm"
              type="submit"
              onClick={() => setIsOpen(!isOpen)}
            >
              <i className="fas fa-trash-alt text-light" />
              Delete Account
            </button>
          )}
          <div className="text-dark mt-2 font-weight-bold">
            Note: Account once deleted can't be reverted back.
          </div>
          <DeletAccountForm isOpen={isOpen} toggle={toggleModal} />
        </ToastBody>
      </Toast>
    </div>
  )
}

export default React.memo(UserSettings)
