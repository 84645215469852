import React from 'react'
import { Table } from 'reactstrap'
import { useSelector } from 'react-redux'

import { userPayments } from '../../selectors/user'

const PaymentsInfo = () => {
  const payments = useSelector(userPayments)
  return (
    <>
      {payments && payments.length === 0 ? (
        <div className="col-sm-6 mt-4">
          <p>No Prevoius Payments found.</p>
        </div>
      ) : (
        <Table responsive bordered className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Premium Plan</th>
              <th>Payment Source</th>
              <th>Payment Mode</th>
              <th>Payment Date</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment, index) => (
              <tr key={String(index)}>
                <th scope="row">{index + 1}</th>
                <td>{payment.plan}</td>
                <td>{payment.payment_source}</td>
                <td>{payment.payment_mode}</td>
                <td>{String(new Date(payment.payment_date))}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default PaymentsInfo
