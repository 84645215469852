import React, { useEffect } from 'react'
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import CustomAlert from '../Alerts'
import InputBox from '../Form/InputBox'

import useForm from '../../hooks/useForm'

import { changePasswordValidations } from '../../validations'
import { postData } from '../../reducers/app'

import { CHANGEPASSWORD } from '../../data/apis'

const init = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  captha: false,
}

const UpdatePassword = () => {
  const dispatch = useDispatch()
  const { values, errors, handleOnChange, handleSubmit, resetForm } = useForm(
    init,
    changePasswordValidations,
    val => {
      dispatch(postData(CHANGEPASSWORD, val))
    }
  )
  const {
    changepassword: { loading, loaded, data, error },
  } = useSelector(state => state.app)

  useEffect(() => {
    if (data && data.msg) resetForm()
  }, [data])

  return (
    <div className="col-sm-5">
      <div className="mt-4">
        <form onSubmit={handleSubmit}>
          <InputBox
            name="oldPassword"
            label="Current Password"
            type="password"
            placeholder="Current Password"
            value={values.oldPassword}
            onChangeInput={handleOnChange}
            error={errors.oldPassword}
          />
          <InputBox
            name="newPassword"
            label="New Password"
            type="password"
            placeholder="New Password"
            value={values.newPassword}
            onChangeInput={handleOnChange}
            error={errors.newPassword}
          />
          <InputBox
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            placeholder="Renter Password"
            value={values.confirmPassword}
            onChangeInput={handleOnChange}
            error={errors.confirmPassword}
          />
          <div className="clear-fix mb-3" />
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Button type="submit" color="success">
              {loading ? 'Please wait...' : 'Change Password'}
            </Button>
          </div>
        </form>
        {loaded && !loading && (
          <CustomAlert type={`${data && data.msg ? 'success' : 'danger'}`}>
            <span>{error || (data && data.msg)}</span>
          </CustomAlert>
        )}
      </div>
    </div>
  )
}

export default UpdatePassword
