import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Fade, Button, Spinner } from 'reactstrap'

import { loadSavedLinks, deleteSavedLinks } from '../../reducers/savedlinks'
import { trimText } from '../../helpers'

const SavedLinks = () => {
  const dispatch = useDispatch()
  const [ids, setIds] = useState({
    selected: [],
    deletingIds: [],
  })

  const {
    data: { results = [] },
    deleting,
    loading,
    loaded,
  } = useSelector(state => state.savedlinks)

  useEffect(() => {
    dispatch(loadSavedLinks())
  }, [])

  const handleSelect = e => {
    const {
      target: { name },
    } = e
    setIds({
      ...ids,
      selected: ids.selected.includes(name)
        ? ids.selected.filter(x => x !== name)
        : [...ids.selected, name],
    })
  }

  const handleCopy = link => e => {
    e.preventDefault()
    window.prompt('Copy to clipboard: Ctrl+C / ⌘+C, Enter', link)
  }

  const handleDelete = id => e => {
    e.preventDefault()
    setIds({
      ...ids,
      deletingIds: [id],
    })
    dispatch(
      deleteSavedLinks({
        ids: [id],
      })
    )
  }

  const handleBulkCopy = e => {
    e.preventDefault()
    window.prompt(
      'Copy to clipboard: Ctrl+C / ⌘+C, Enter.\nYou can paste this in Downloader directly.',
      ids.selected.join('\n')
    )
  }

  const handleBulkDelete = e => {
    e.preventDefault()
    const dIds = results
      .filter(item => ids.selected.includes(item.link))
      .map(x => x.id)
    setIds({
      deletingIds: dIds,
      selected: [],
    })
    dispatch(
      deleteSavedLinks({
        ids: dIds,
      })
    )
  }

  return (
    <section className="mt-4 col-auto col-sm-12">
      <Fade in={ids.selected.length > 0} tag="em" className="pb-3">
        <Button color="primary" size="sm" onClick={handleBulkCopy}>
          Copy Mutliple
        </Button>{' '}
        <Button color="danger" size="sm" onClick={handleBulkDelete}>
          Delete Multiple
        </Button>
      </Fade>
      <span className="pb-3 float-right">
        <i
          className="fas fa-clipboard"
          style={{ color: '#0000ff', fontSize: '15px' }}
        />{' '}
        => Copy Link
        <i
          className="far fa-trash-alt"
          style={{ color: '#FF0000', fontSize: '15px', paddingLeft: '10px' }}
        />{' '}
        => Delete
      </span>
      {!loading && loaded && results.length === 0 && (
        <span>No links saved yet.</span>
      )}
      {loading && !loaded ? (
        <div className="text-center">
          <Spinner color="success" />
        </div>
      ) : (
        results.length > 0 && (
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Link</th>
                <th>Filename</th>
                <th>Size</th>
                <th>Added</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {results.map(item => (
                <tr key={item.id}>
                  <td>
                    <input
                      id={item.id}
                      type="checkbox"
                      name={item.link}
                      onChange={handleSelect}
                      checked={
                        ids.selected.includes(item.link) ? 'checked' : ''
                      }
                    />
                  </td>
                  <td>
                    {/* eslint-disable jsx-a11y/label-has-for */}
                    <label htmlFor={item.id}>
                      {trimText(item.link, 36, 2)}
                    </label>
                  </td>
                  <td>{trimText(item.filename, 8, 5)}</td>
                  <td>{item.filesize}</td>
                  <td>{item.added}</td>
                  <td>
                    <i
                      title="Copy Link To clipboard"
                      className="clickable-icon fas fa-clipboard"
                      style={{ color: '#0000ff', fontSize: '18px' }}
                      role="presentation"
                      onClick={handleCopy(item.link)}
                    />
                    {deleting && ids.deletingIds.includes(item.id) ? (
                      <Spinner size="sm" color="danger" />
                    ) : (
                      <i
                        title="Delete Link"
                        className="clickable-icon far fa-trash-alt"
                        style={{
                          color: '#FF0000',
                          fontSize: '18px',
                          paddingLeft: '10px',
                        }}
                        role="presentation"
                        onClick={handleDelete(item.id)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )
      )}
    </section>
  )
}

export default SavedLinks
