import React from 'react'
import PropTypes from 'prop-types'
import Wrapper from '../Wrapper'
import Tabs from './Tabs'

const styles = require('../Downloader/index.module.scss')

const Profile = ({ location }) => {
  return (
    <Wrapper title="My Profile" colSize="col-sm-9 col-9" showAds={false}>
      <div className="container col-sm-12 profilePage">
        <section className={styles.block}>
          <Tabs location={location} />
        </section>
      </div>
    </Wrapper>
  )
}

Profile.defaultProps = {
  location: {},
}

Profile.propTypes = {
  location: PropTypes.object,
}

export default Profile
