import React from 'react'

import Profile from '../../components/Profile'
import PrivateRoute from '../../components/PrivateRoute'
import SEO from '../../components/SEO'

const ProfilePage = () => {
  return (
    <>
      <SEO page="profile" />
      <PrivateRoute component={Profile} />
    </>
  )
}

export default ProfilePage
