import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from 'reactstrap'

import UpdatePassword from './UpdatePassword'
import PaymentsInfo from './PaymentsInfo'
import UserSettings from './Settings'
import UserInfo from '../UserInfo'
import SavedLinks from './SavedLinks'

const getTab = hash => {
  switch (hash) {
    case '#settings':
      return '2'
    case '#saved-links':
      return '3'
    case '#payments-history':
      return '4'
    case '#change-password':
      return '5'
    default:
      return '1'
  }
}

const tableSettings = {
  responsive: true,
  bordered: true,
}

const ProfileTabs = ({ location }) => {
  const [activeTab, setActiveTab] = useState(1)
  const { hash } = location

  useEffect(() => {
    setActiveTab(getTab(hash))
  }, [])

  const toggle = (tabId, tabName) => e => {
    e.preventDefault()
    if (activeTab !== tabId) {
      setActiveTab(tabId)
      navigate(`/user/profile#${tabName}`)
    }
  }

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={toggle('1', '')}
          >
            User Info
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={toggle('2', 'settings')}
          >
            Settings
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : ''}
            onClick={toggle('3', 'saved-links')}
          >
            Saved Links
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '4' ? 'active' : ''}
            onClick={toggle('4', 'payments-history')}
          >
            Payments History
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '5' ? 'active' : ''}
            onClick={toggle('5', 'change-password')}
          >
            Change Password
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <section className="mt-4 col-auto col-sm-8">
              <UserInfo tableSettings={tableSettings} />
            </section>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <UserSettings />
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <SavedLinks />
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <PaymentsInfo />
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <UpdatePassword />
          </Row>
        </TabPane>
      </TabContent>
    </div>
  )
}

ProfileTabs.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ProfileTabs
